import { RootState } from '@/store/rootReducer';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function PublicRouteController() {
    const { state }: any = useLocation();

    const isAuthenticated = useSelector((rs: RootState) => rs.auth.login.isAuthenticated);

    return { state, isAuthenticated };
}

export default PublicRouteController;
