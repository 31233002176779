import { Navigate } from 'react-router-dom';
import PublicRouteController from './PublicRoute.controller';
import { PublicRouteProps } from './PublicRoute.types';

function PublicRoute(props: PublicRouteProps) {
    const { state, isAuthenticated } = PublicRouteController();

    return !isAuthenticated ? props.children : <Navigate to={state ? state.path : '/'} replace />;
}

export default PublicRoute;
