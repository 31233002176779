.container {
    height: 250px;
    width: 350px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    overflow: hidden;
    .map {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .data {
        font-size: 12px;
        width: 50%;
        background-color: var(--color-info);
        padding: 0 10px;

        .statusBadge {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;

            .badge {
                border-radius: 0 5px 0 20px;
                width: fit-content;
                color: white;
                padding: 5px;
                position: relative;
                font-weight: bolder;
                left: 10px;
                &__low {
                    background-color: rgb(7, 226, 7);
                }
                &__medium {
                    background-color: rgb(255, 217, 0);
                }
                &__high {
                    background-color: rgb(255, 145, 0);
                }
                &__urgent {
                    background-color: tomato;
                }
            }
        }
        .status {
            width: 80px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid white;
            border-radius: 10px;
            color: white;
            margin-bottom: 5px;
        }
        .title {
            font-weight: bolder;
            text-decoration: underline;
            margin-bottom: 5px;
        }
        .label {
            color: white;
        }
        .text {
            color: white;
            font-weight: bolder;
            margin-bottom: 5px;
            max-width: 280px;
            max-height: 30px;
            overflow-wrap: break-word;
            overflow-y: hidden;
        }
    }
}
