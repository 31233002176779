import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { authActions } from '../auth';
import { rootThunk } from '../rootThunk';
export interface IncidenceData {
    incidence: {
        id: string;
        description: string;
        priority: string;
        status: string;
    };
    citizen: {
        nif: string;
        name: string;
        last_name: string;
        email: string;
        phone_numbers: string[];
    };
    property: {
        latitude: number;
        longitude: number;
        is_company: boolean;
        has_elders: boolean;
        has_minors: boolean;
        people_on_property: number;
    };
}

export const patchIncidenceThunk = createAsyncThunk<
    IncidenceData,
    { incidenceId: string; status: string },
    { rejectValue: Error }
>('patchIncidenceData', async (params, thunkApi) => {
    return request({
        url: `/api/emergency_services/incidences/${params.incidenceId}`,
        method: 'PATCH',
        data: { status: params.status },
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    })
        .then((res: ApiResponse<IncidenceData>) => {
            toast.success('La incidencia se ha actualizado con éxito');
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            toast.error('Falló al actualizar la incidencia');
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getIncidenceSlice = createSlice({
    name: 'patchIncidence',
    initialState: {
        incidence: {
            id: '1',
            description: '',
            priority: '',
            status: ''
        },
        citizen: {
            nif: '',
            name: '',
            last_name: '',
            email: '',
            phone_numbers: []
        },
        property: {
            latitude: 0,
            longitude: 0,
            is_company: false,
            has_elders: false,
            has_minors: false,
            people_on_property: 0
        }
    } as IncidenceData,
    reducers: {},
    extraReducers: rootThunk
});
