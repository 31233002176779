import Card from '@/components/Card/Card';
import { useAppDispatch } from '@/index';
import { getIncidenceListThunk } from '@/store/incidence/getIncidenceList.slice';
import { RootState } from '@/store/rootReducer';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.scss';
import { authActions } from '@/store/auth';
const Home = () => {
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();
    const [filterIncidence, setFilterIncidence] = useState<'all' | 'in_progress' | 'pending'>(
        'all'
    );

    const data = useSelector((state: RootState) => state.incidence.getIncidenceList.data);

    const onCloseSession = () => {
        appDispatch(authActions.logout());
    };

    useEffect(() => {
        appDispatch(getIncidenceListThunk({}));
    }, []);
    return (
        <div className={styles.container}>
            <div className={styles.layer}>
                <div className={styles.content}>
                    <div className={styles.buttons}>
                        <button className={styles.buttons__close} onClick={onCloseSession}>
                            Cerrar Sesión
                        </button>
                    </div>
                    <div className={styles.header}>
                        <button
                            className={classNames(
                                styles.option,
                                filterIncidence === 'all' && styles.option__active
                            )}
                            onClick={() => {
                                setFilterIncidence('all');
                            }}
                        >
                            Todas
                        </button>
                        <button
                            className={classNames(
                                styles.option,
                                filterIncidence === 'pending' && styles.option__active
                            )}
                            onClick={() => {
                                setFilterIncidence('pending');
                            }}
                        >
                            Pendiente
                        </button>
                        <button
                            className={classNames(
                                styles.option,
                                filterIncidence === 'in_progress' && styles.option__active
                            )}
                            onClick={() => {
                                setFilterIncidence('in_progress');
                            }}
                        >
                            En curso
                        </button>
                    </div>
                    <div className={styles.cards}>
                        {data &&
                            data
                                .filter((e) => {
                                    if (filterIncidence === 'all') return e.incidence.status;
                                    return e.incidence.status === filterIncidence;
                                })
                                .map((element) => {
                                    return (
                                        <Card
                                            handleClick={() =>
                                                navigate(`/info/${element.incidence.id}`)
                                            }
                                            data={element}
                                        />
                                    );
                                })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
