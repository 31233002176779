@import '@/styles/tools/responsive';
.container {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background-image: url('./../../assets/images/bg.jpg');
    backdrop-filter: blur(4px);
    background-size: cover;
}
.layer {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.339);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.content {
    width: 90%;
    margin-top: 50px;
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 25px;
        max-width: 400px;
        .option {
            border: none;
            padding: 5px 10px;
            background-color: white;
            border: 2px solid var(--color-info);
            border-radius: 20px;
            color: var(--color-info);
            cursor: pointer;
            &__active {
                background-color: var(--color-info);
                color: white;
            }
        }
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        margin-bottom: 50px;
        //   grid-auto-flow: dense;
        gap: 20px;
    }
}
.buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin-bottom: 20px;
    &__close {
        border: none;
        outline: none;
        background-color: salmon;
        color: white;
        border-radius: 5px;
        padding: 7px;
        cursor: pointer;
        font-weight: bolder;
    }
    &__close:hover {
        background-color: red;
    }
}

@include breakpoint(smallTablet) {
}
@include breakpoint(mobile) {
}
