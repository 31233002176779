import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

const ErrorDiccionary: any = {
    404: 'Credenciales invalidas',
    500: 'Conexión fallida'
};

export interface UserData {
    cif: string;
    email: string;
    id: string;
    maintenance_type: string;
    role: string;
    token: string;
    username: string;
}
export const loginThunk = createAsyncThunk<
    UserData,
    { email: string; password: string },
    { rejectValue: Error }
>('auth/login', async (params, thunkApi) => {
    return request({
        url: '/api/emergency_services/login',
        method: 'POST',
        data: params
    } as ApiRequest<{ email: string; password: string }>)
        .then((res: ApiResponse<UserData>) => {
            accessToken.set(res.data.token);

            toast.success('Bienvenido');
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            toast.error(ErrorDiccionary[err.status]);
            return thunkApi.rejectWithValue(err.data);
        });
});

type LoginState = {
    isLoading: boolean;
    error: Error | null;
    userData: UserData | null;
    isAuthenticated: boolean;
};

export const authLoginSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: accessToken.exists(),
        isLoading: false,
        userData: {
            cif: '',
            email: '',
            id: '',
            maintenance_type: '',
            role: '',
            token: '',
            username: ''
        },
        error: null
    } as LoginState,
    reducers: {
        logout: (state) => {
            accessToken.remove();
            state.isAuthenticated = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginThunk.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = null;
            state.userData = payload;
            state.isAuthenticated = true;
        });
        builder.addCase(loginThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(loginThunk.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isAuthenticated = false;

            if (payload) {
                state.error = payload;
            }
        });
    }
});
