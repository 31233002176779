import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Layout from './layout/Layout';
import PrivateRoute from './layout/Routes/PrivateRoute';
import PublicRoute from './layout/Routes/PublicRoute';
import Home from './pages/Home/Home';
import Info from './pages/Info/Info';
import Login from './pages/Login/Login';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <Home />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/info/:id"
                        element={
                            <PrivateRoute>
                                <Info />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <PublicRoute>
                                <Login />
                            </PublicRoute>
                        }
                    />
                </Routes>
            </Layout>
            <ToastContainer
                position="top-right"
                limit={3}
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </BrowserRouter>
    );
};

export default App;
