import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authActions } from '../auth';
export interface IncidenceData {
    incidence: {
        id: string;
        description: string;
        priority: string;
        status: string;
        date: string;
    };
    citizen: {
        nif: string;
        name: string;
        last_name: string;
        email: string;
        phone_numbers: string[];
    };
    property: {
        latitude: number;
        longitude: number;
        is_company: boolean;
        has_elders: boolean;
        has_minors: boolean;
        people_on_property: number;
    };
}
export interface GetIncidenceData {
    data: IncidenceData;
}

export const getIncidenceThunk = createAsyncThunk<
    IncidenceData,
    { incidenceId: string },
    { rejectValue: Error }
>('incidenceData', async (params, thunkApi) => {
    return request({
        url: `/api/emergency_services/incidences/${params.incidenceId}`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
        //data: params
    })
        .then((res: ApiResponse<IncidenceData>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getIncidenceSlice = createSlice({
    name: 'getIncidence',
    initialState: {
        data: {
            incidence: {
                id: '',
                description: '',
                priority: '',
                status: '',
                date: ''
            },
            citizen: {
                nif: '',
                name: '',
                last_name: '',
                email: '',
                phone_numbers: [] as string[]
            },
            property: {
                latitude: 0,
                longitude: 0,
                is_company: false,
                has_elders: false,
                has_minors: false,
                people_on_property: 0
            }
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getIncidenceThunk.fulfilled, (state, { payload }) => {
            state.data = payload;
        });
    }
});
