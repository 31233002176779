import MapMarker from '@/components/MapMarker/MapMarker';
import { useAppDispatch } from '@/index';
import { getIncidenceThunk } from '@/store/incidence/getIncidence.slice';
import { patchIncidenceThunk } from '@/store/incidence/patchIncidence.slice';
import { RootState } from '@/store/rootReducer';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Info.module.scss';

const Info = () => {
    const navigate = useNavigate();
    const params = useParams();
    const appDispatch = useAppDispatch();

    const citizen = useSelector((state: RootState) => state.incidence.getIncidence.data.citizen);
    const incidence = useSelector(
        (state: RootState) => state.incidence.getIncidence.data.incidence
    );
    const property = useSelector((state: RootState) => state.incidence.getIncidence.data.property);
    const [infoState, setInfoState] = useState<string>();

    const showInMapClicked = () => {
        window.open('https://maps.google.com?q=' + property.latitude + ',' + property.longitude);
    };

    useEffect(() => {
        if (params) appDispatch(getIncidenceThunk({ incidenceId: params.id ?? '' }));
    }, []);
    useEffect(() => {
        setInfoState(incidence.status);
    }, [incidence.status]);
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {property.latitude !== 0 && property.longitude !== 0 && (
                    <MapMarker position={{ lat: property.latitude, lng: property.longitude }} />
                )}
            </div>
            <div className={styles.content}>
                <div className={styles.body}>
                    <button className={styles.button} onClick={showInMapClicked}>
                        Cómo llegar
                    </button>
                    <div className={styles.title}>Descripción de la incidencia</div>

                    <div className={styles.row}>
                        <div className={styles.text}>{incidence.description}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Fecha:</div>
                        <div className={styles.text}>{incidence.date}</div>
                    </div>
                    <div className={styles.title}>Información personal</div>

                    <div className={styles.row}>
                        <div className={styles.label}>Nombre:</div>
                        <div className={styles.text}>{citizen.name}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>DNI:</div>
                        <div className={styles.text}>{citizen.nif}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>Teléfono:</div>
                        <div className={styles.text}>{citizen.phone_numbers.toString()}</div>
                    </div>

                    <div className={styles.title}>Ubicación de la propiedad</div>
                    <div className={styles.column}>
                        <div className={styles.label}>Coordenadas de la propiedad</div>
                        <div
                            className={styles.text}
                        >{`${property.latitude}, ${property.longitude}`}</div>
                    </div>

                    <div className={styles.title}>Información de la propiedad</div>
                    <div className={styles.row}>
                        <div className={styles.label}>¿Es una empresa?</div>
                        <div className={styles.text}>{property.is_company ? 'Si' : 'No'}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>¿Hay personas mayores de 65 años?</div>
                        <div className={styles.text}>{property.has_elders ? 'Si' : 'No'}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>¿Hay personas menores de 18 años?</div>
                        <div className={styles.text}>{property.has_minors ? 'Si' : 'No'}</div>
                    </div>
                    <div className={styles.selectorState}>
                        <div
                            className={classNames(
                                styles.selectorState__option,
                                infoState === 'pending' && styles[`selectorState__option--active`]
                            )}
                            onClick={() => {
                                setInfoState('pending');
                            }}
                        >
                            Pendiente
                        </div>
                        <div
                            className={classNames(
                                styles.selectorState__option,
                                infoState === 'in_progress' &&
                                    styles[`selectorState__option--active`]
                            )}
                            onClick={() => {
                                setInfoState('in_progress');
                            }}
                        >
                            En curso
                        </div>
                        <div
                            className={classNames(
                                styles.selectorState__option,
                                infoState === 'solved' && styles[`selectorState__option--active`]
                            )}
                            onClick={() => {
                                setInfoState('solved');
                            }}
                        >
                            Resolver
                        </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        <button
                            className={styles.updateButton}
                            onClick={() => {
                                navigate('/');
                            }}
                        >
                            Regresar
                        </button>
                        <button
                            className={styles.updateButton}
                            onClick={() => {
                                navigate('/');
                                appDispatch(
                                    patchIncidenceThunk({
                                        incidenceId: params.id ?? '',
                                        status: infoState ?? incidence.status
                                    })
                                );
                            }}
                        >
                            Actualizar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Info;
