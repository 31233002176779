@import '@/styles/tools/responsive';
.container {
    min-height: 100vh;
    width: 100%;
    .header {
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content {
        min-height: 70vh;
        background-color: var(--color-info);
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 5px;
        .body {
            margin: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 18px;
        }
        .button {
            width: 300px;
            border: none;
            padding: 10px;
            border-radius: 20px;
            border: 2px solid var(--color-info);
            color: var(--color-info);
            align-self: center;
            margin-bottom: 20px;
            cursor: pointer;
            font-weight: bolder;
        }
        .row {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            gap: 10px;
        }
        .column {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            gap: 10px;
        }
        .title {
            font-weight: bolder;
            text-decoration: underline;
            margin-bottom: 10px;
        }
        .label {
            color: white;
        }
        .text {
            color: white;
            font-weight: bolder;
            max-width: 350px;

            overflow-wrap: break-word;
        }
    }
}

.selectorState {
    display: flex;
    flex-direction: row;
    width: 300px;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    margin-top: 20px;
    &__option {
        background-color: whitesmoke;
        padding: 3px;
        width: 100%;
        border: 1px solid rgba(136, 136, 136, 0.421);
        border-radius: 3px;
        color: #777;
        text-align: center;
        cursor: pointer;
        &--active {
            background-color: white;
            padding: 5px;
            color: var(--color-info);
            zoom: 1.1;
        }
    }
}
.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 30px;
    margin: 20px 0;
}
.updateButton {
    border: none;
    border: 2px solid white;
    background-color: var(--color-info);
    color: white;
    border-radius: 20px;
    padding: 5px;
    width: 130px;
    align-self: center;
    margin-top: 20px;
    cursor: pointer;
    font-weight: bolder;
}

@include breakpoint(mobile) {
    .container {
        .content {
            .text {
                max-width: 200px;
                overflow-wrap: break-word;
            }
        }
    }
    .buttonContainer {
        flex-direction: column;
        gap: 5px;
    }
}
