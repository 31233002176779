import { IncidenceData } from '@/store/incidence/getIncidenceList.slice';
import classNames from 'classnames';
import MapMarker from '../MapMarker/MapMarker';
import styles from './Card.module.scss';

export interface CardProps {
    handleClick: () => void;
    data: IncidenceData;
}
const Card = ({ handleClick, data }: CardProps) => {
    const incidenceStatus: any = {
        pending: 'Pendiente',
        in_progress: 'En curso',
        solved: 'Resuelta'
    };
    const incidencePriority: any = {
        low: 'Baja',
        medium: 'Media',
        high: 'Alta',
        urgent: 'Urgente'
    };
    return (
        <div className={styles.container}>
            <div className={styles.map} onClick={handleClick}>
                {data.property.latitude !== 0 && data.property.longitude !== 0 && (
                    <MapMarker
                        position={{ lat: data.property.latitude, lng: data.property.longitude }}
                    />
                )}
            </div>
            <div className={styles.data} onClick={handleClick}>
                <div className={styles.statusBadge}>
                    <div
                        className={classNames(
                            styles.badge,
                            styles[`badge__${data.incidence.priority}`]
                        )}
                    >
                        {incidencePriority[data.incidence.priority]}
                    </div>
                </div>
                <div className={styles.status}>{incidenceStatus[data.incidence.status]}</div>
                <div className={styles.title}>Información personal</div>
                <div className={styles.label}>Nombre:</div>
                <div
                    className={styles.text}
                >{`${data.citizen.name} ${data.citizen.last_name}`}</div>
                <div className={styles.label}>DNI:</div>
                <div className={styles.text}>{data.citizen.nif}</div>
                <div className={styles.label}>Teléfono:</div>
                <div className={styles.text}>{data.citizen.phone_numbers.toString()}</div>
                <div className={styles.title}>Ubicación de la propiedad</div>
                <div className={styles.label}>Coordenadas de la propiedad</div>
                <div
                    className={styles.text}
                >{`${data.property.latitude}, ${data.property.longitude}`}</div>
            </div>
        </div>
    );
};

export default Card;
