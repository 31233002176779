import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { incidenceReducer } from './incidence';
import { loaderReducer } from './loader';
export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
    auth: authReducer,
    loader: loaderReducer,
    incidence: incidenceReducer
});

export default rootReducer;
