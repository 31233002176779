import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import styles from './MapMarker.module.scss';
const containerStyle = {
    width: '100%',
    height: '100%'
};

const center = { lat: -34.397, lng: 150.644 };

export interface MapMarkerProps {
    position?: { lat: number; lng: number };
}
const MapMarker = ({ position }: MapMarkerProps) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCeWFEt22CP87zpri8-qJPzKxx2v5Qcibo',
        libraries: ['places']
    });

    const [map, setMap] = useState(null);

    const [markerPosition, setMarkerPosition] = useState<{ lat: number; lng: number }>(center);
    const onLoad = useCallback(
        function callback(map: any) {
            // This is just an example of getting and using the map instance!!! don't just blindly copy!
            //const bounds = new window.google.maps.LatLngBounds(position);
            // map.fitBounds(bounds);
            const marker = new window.google.maps.Marker({
                // The below line is equivalent to writing:
                // position: new google.maps.LatLng(-34.397, 150.644)
                position: position,
                map: map
            });

            setMap(map);
        },
        [position]
    );
    const onUnmount = useCallback(function callback(map: any) {
        setMap(null);
    }, []);

    // useEffect(() => {
    //     if (position) {
    //         setMarkerPosition({ lat: position.lat, lng: position.lng });
    //     }
    // }, [position.lat, position.lng]);

    return isLoaded && position ? (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={position}
                zoom={8}
                onLoad={onLoad}
                onUnmount={onUnmount}

                //options={{ scrollwheel: false, gestureHandling: 'none' }}
            >
                {/* Child components, such as markers, info windows, etc. */}

                {/* {/* <Marker position={{ lat: position.lat, lng: position.lng }} /> */}
                {/* <Marker position={markerPosition} /> */}
            </GoogleMap>
        </>
    ) : (
        <>...Cargando</>
    );
};

export default MapMarker;
