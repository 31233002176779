import { combineReducers } from 'redux';
import { getIncidenceSlice } from './getIncidence.slice';
import { getIncidenceListSlice } from './getIncidenceList.slice';

export const incidenceReducer = combineReducers({
    getIncidence: getIncidenceSlice.reducer,
    getIncidenceList: getIncidenceListSlice.reducer
});

export const incidenceActions = {
    ...getIncidenceSlice.actions,
    ...getIncidenceListSlice.actions
};
